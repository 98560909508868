import { createBrowserHistory as createHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';

export const history = createHistory();
const routeMiddleware = routerMiddleware(history);

// Middleware you want to use in production:
const enhancer = applyMiddleware(routeMiddleware);

export function configureStore(initialState) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  return createStore(createRootReducer(history), initialState, enhancer);
}
